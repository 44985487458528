<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <v-icon :left="true">
        {{ $vuetify.icons.values.alert }}
      </v-icon>
      <h6 class="text-h6">
        {{ $trans("update_recurring_event_title") }}
      </h6>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1">
        {{ $trans("update_recurring_event_question") }}
      </p>
      <p class="accent_light--text text-subtitle-1">
        {{ $trans("no_way_to_undo_action") }}
      </p>
    </v-card-text>

    <v-card-actions v-if="!isBlockingSpot">
      <v-checkbox
        v-model="skipNotifications"
        :disabled="isLoading || disableSkipNotifications"
        dense
        hide-details
      >
        <template #label>
          <v-tooltip max-width="400" right>
            <template #activator="{ on }">
              <div v-on="on">
                <span>{{
                  $trans("event_updated_no_notifications_label")
                }}</span>
              </div>
            </template>
            {{ $trans("booking_no_notifications_tooltip") }}
          </v-tooltip>
        </template>
      </v-checkbox>
    </v-card-actions>

    <v-card-actions>
      <v-radio-group
        v-model="updateEventType"
        :disabled="isLoading"
        dense
        hide-details
      >
        <v-radio
          v-if="!recurringParamsModified"
          :label="$trans('delete_recurring_booking_option_1')"
          :value="1"
        />
        <v-radio
          :label="$trans('delete_recurring_booking_option_2')"
          :value="2"
        />
        <v-radio
          :label="$trans('delete_recurring_booking_option_3')"
          :value="3"
        />
      </v-radio-group>
    </v-card-actions>

    <v-card-actions>
      <v-spacer />
      <v-btn color="light" text @click="$emit('close', { success: false })">
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn
        :disabled="isLoading"
        color="accent_light"
        text
        @click="updateEvent"
      >
        {{ $trans("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { parseRruleString } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "UpdateRecurringEventDialog",
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
      skipNotifications: false,
      disableSkipNotifications: false,
      isBlockingSpot: false,
      isGroupBooking: false,
      updateEventType: 1,
      eventData: null,
    };
  },
  computed: {
    recurringParamsModified() {
      const rrule1 = this.eventData.initialRecurrenceParams
        ? parseRruleString(this.eventData.initialRecurrenceParams)
        : null;
      const rrule2 = this.eventData.recurrenceParams
        ? parseRruleString(this.eventData.recurrenceParams)
        : null;

      if (!rrule1 || !rrule2) {
        return true;
      }

      const date1 = rrule1.UNTIL ? rrule1.UNTIL.getTime() : 1;
      const date2 = rrule2.UNTIL ? rrule2.UNTIL.getTime() : 1;

      return !(
        rrule1.FREQ === rrule2.FREQ &&
        parseInt(rrule1.INTERVAL || 1) === parseInt(rrule2.INTERVAL || 1) &&
        parseInt(rrule1.COUNT || 1) === parseInt(rrule2.COUNT || 1) &&
        date1 === date2
      );
    },
  },
  created() {
    this.skipNotifications = this.getConfirmDialog.data.skipNotifications;
    this.disableSkipNotifications =
      this.getConfirmDialog.data.disableSkipNotifications;
    this.isBlockingSpot = this.getConfirmDialog.data.isBlockingSpot;
    this.eventData = this.getConfirmDialog.data.eventData;
    this.isGroupBooking = this.getConfirmDialog.data.isGroupBooking;

    if (this.recurringParamsModified) {
      this.updateEventType = 2;
    }
  },
  methods: {
    ...mapActions({
      updateBooking: "booking/updateBooking",
      updateGroupBooking: "booking/updateGroupBooking",
      updateUnavailableBookingSpot: "booking/updateUnavailableBookingSpot",
    }),
    updateEvent() {
      this.$emit("loading", true);
      this.isLoading = true;

      const data = { ...this.eventData };

      if (this.isGroupBooking) {
        // We need to make sure we start from the lowest id.
        const bookings = this.$helpers.cloneObject(data.data.bookings);
        bookings.sort((a, b) => a.id - b.id);

        if (this.updateEventType === 1) {
          data.recurrenceParams = null;
        }

        if (this.updateEventType === 2 && bookings[0]) {
          data.startFromId = bookings[0].id;
        }
      } else {
        if (this.updateEventType === 1) {
          data.groupId = null;
        }

        if (this.updateEventType === 2) {
          data.startFromId = data.id;
        }
      }

      data.skipNotifications = this.skipNotifications;

      const action = this.isBlockingSpot
        ? this.updateUnavailableBookingSpot
        : this.isGroupBooking
          ? this.updateGroupBooking
          : this.updateBooking;

      action(data)
        .then(() => {
          this.handleSuccess();
        })
        .catch((error) => {
          this.handleFail(error);
        });
    },
    handleSuccess() {
      this.$emit("loading", false);
      this.isLoading = false;
      this.getConfirmDialog.close({ success: true });
      this.$emit("close", { success: true });
    },
    handleFail(error) {
      errorNotification(null, error);
      this.isLoading = false;
      this.$emit("loading", false);
    },
  },
};
</script>
